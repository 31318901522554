import React from "react";
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from "react-icons/fa";

const ContactCards = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 px-6 py-10 bg-gray-100">
      {/* Phone Card */}
      <div className="flex flex-col items-center text-center bg-gray-900 text-white p-6 rounded-lg shadow-md">
        <div className="w-12 h-12 bg-lime-600 flex items-center justify-center rounded-full mb-4">
          <FaPhoneAlt className="text-white text-xl" />
        </div>
        <h3 className="text-lg font-semibold mb-2">Phone</h3>
        <a
          href="https://wa.me/c/923118285630"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
        >
          +923118285630
        </a>
      </div>

      {/* Address Card */}
      <div className="flex flex-col items-center text-center bg-lime-600 text-white p-6 rounded-lg shadow-md">
        <div className="w-12 h-12 bg-white flex items-center justify-center rounded-full mb-4">
          <FaMapMarkerAlt className="text-lime-600 text-xl" />
        </div>
        <h3 className="text-lg font-semibold mb-2">Address</h3>
        <p className="text-sm">Sherwani Colony Haji Pura Sialkot</p>
       
      </div>

      {/* Email Card */}
      <div className="flex flex-col items-center text-center bg-gray-900 text-white p-6 rounded-lg shadow-md">
        <div className="w-12 h-12 bg-lime-600 flex items-center justify-center rounded-full mb-4">
          <FaEnvelope className="text-white text-xl" />
        </div>
        <h3 className="text-lg font-semibold mb-2">Email</h3>
        <a
          href="mailto:wekandainterprises07@gmail.com"
          className="hover:underline"
        >
          wekandainterprises07@gmail.com
        </a>
      </div>
    </div>
  );
};

export default ContactCards;
