import React from "react";
import { useNavigate } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import person1 from "../assets/person1.jpg";
import person2 from "../assets/person2.jpg";
import person3 from "../assets/person3.jpg";
import person4 from "../assets/person4.jfif";

const CallToAction = () => {
  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className="bg-black text-white py-16 px-4 sm:px-8 lg:px-16 text-center">
      {/* Avatar and Stars Section */}
      <div className="flex justify-center items-center mb-6 flex-wrap">
        <div className="flex">
          <img
            className="w-10 sm:w-12 h-10 sm:h-12 rounded-full"
            src={person1}
            alt="Avatar 1"
          />
          <img
            className="w-10 sm:w-12 h-10 sm:h-12 rounded-full -ml-2"
            src={person2}
            alt="Avatar 2"
          />
          <img
            className="w-10 sm:w-12 h-10 sm:h-12 rounded-full -ml-2"
            src={person3}
            alt="Avatar 3"
          />
          <img
            className="w-10 sm:w-12 h-10 sm:h-12 rounded-full -ml-2"
            src={person4}
            alt="Avatar 4"
          />
        </div>
        <div className="flex justify-center items-center mt-4 sm:mt-0 ml-4">
          {[...Array(5)].map((_, index) => (
            <FaStar
              key={index}
              className="text-white text-sm sm:text-base lg:text-xl mx-0.5"
            />
          ))}
        </div>
      </div>

      {/* Text Section */}
      <h5 className="text-lime-400 mb-4 text-sm sm:text-base lg:text-lg">
        2000+ Happy Clients
      </h5>
      <h1 className="text-2xl sm:text-4xl lg:text-5xl font-bold mb-6">
        We worked with more than 500 brands
      </h1>
      <p className="text-sm sm:text-base lg:text-lg text-gray-300 mb-8">
        If anyone has trust issues about quality, text any of our customers and
        ask about our services. Many customers mentioned us on Instagram.
      </p>

      {/* Buttons Section */}
      <div className="flex flex-wrap justify-center gap-4">
        <button
          className="bg-lime-500 text-black px-4 sm:px-6 py-2 sm:py-3 rounded hover:bg-lime-400 text-sm sm:text-base"
          onClick={() => handleNavigate("/contact")}
        >
          Get Started
        </button>
        <button
          className="bg-white text-black px-4 sm:px-6 py-2 sm:py-3 rounded hover:bg-gray-200 text-sm sm:text-base"
          onClick={() => handleNavigate("/aboutus")}
        >
          Learn More
        </button>
      </div>
    </div>
  );
};

export default CallToAction;
