import React, { useState, useEffect } from 'react';

const Testimonials = () => {
  const videos = [
    { src: "/videos/feedback1.mp4", alt: "Testimonial Video 1" },
    { src: "/videos/feedback2.mp4", alt: "Testimonial Video 2" },
    { src: "/videos/feedback3.mp4", alt: "Testimonial Video 3" },
    { src: "/videos/feedback4.mp4", alt: "Testimonial Video 4" },
  ];

  const [currentSlide, setCurrentSlide] = useState(0);
  const [videosPerSlide, setVideosPerSlide] = useState(3);  

  // Update the number of visible videos based on screen width
  useEffect(() => {
    const updateVideosPerSlide = () => {
      if (window.innerWidth < 768) { 
        setVideosPerSlide(1);
      } else if (window.innerWidth < 1024) { 
        setVideosPerSlide(1);
      } else {  
        setVideosPerSlide(3);
      }
    };

    // Set the initial videos per slide on component mount
    updateVideosPerSlide();

    // Update on window resize
    window.addEventListener('resize', updateVideosPerSlide);
    return () => {
      window.removeEventListener('resize', updateVideosPerSlide);
    };
  }, []);

  const getVisibleVideos = () => {
    const startIndex = currentSlide;
    const endIndex = (currentSlide + videosPerSlide) % videos.length;
    if (startIndex < endIndex) {
      return videos.slice(startIndex, endIndex);
    }
    return [...videos.slice(startIndex), ...videos.slice(0, endIndex)];
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + videosPerSlide) % videos.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - videosPerSlide + videos.length) % videos.length
    );
  };

  return (
    <div className="py-16 bg-gray-900 text-white">
      <h1 className="text-4xl text-center font-bold mb-5">
        Hear it directly from our clients.
      </h1>
      <p className="text-xl text-center mb-10 mx-24">
        Hear what our clients have to say. Our testimonials reflect the satisfaction our clients have in our services.
      </p>

      {/* Video Slider */}
      <div className="relative mx-14">
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 items-stretch">
          {getVisibleVideos().map((video, index) => (
            <div
              key={index}
              className="flex justify-center items-center p-4"
            >
              <div className="relative w-full border-2 border-lime-800 rounded-lg shadow-lg bg-gray-900">
                <video
                  className="rounded-lg"
                  controls
                  width="100%"
                  height="auto"
                  src={video.src}
                  alt={video.alt}
                >
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation Buttons */}
        <button
          onClick={prevSlide}
          className="absolute top-1/2 left-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
        >
          &lt;
        </button>
        <button
          onClick={nextSlide}
          className="absolute top-1/2 right-4 transform -translate-y-1/2 bg-gray-800 text-white p-2 rounded-full"
        >
          &gt;
        </button>

        {/* Dots to indicate slide position */}
        <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
          {Array.from({ length: Math.ceil(videos.length / videosPerSlide) }).map(
            (_, index) => (
              <div
                key={index}
                onClick={() => setCurrentSlide(index * videosPerSlide)}
                className={`w-3 h-3 rounded-full ${
                  currentSlide / videosPerSlide === index
                    ? "bg-white"
                    : "bg-gray-400"
                } cursor-pointer`}
              ></div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
