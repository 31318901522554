import React from 'react'
import HowWeWork from '../components/HowWeWork'
import ContactForm from '../components/ContactForm'
import Abouthero from '../components/Abouthero'
import ContactCards from '../components/ContactCards'

const ContactUS = () => {
  return (
    <div>
      <Abouthero title="Contact US"/>
      <ContactCards/>
      <HowWeWork/>
      <ContactForm/>
    </div>
  )
}

export default ContactUS
