import React from "react";
import { FaPhoneVolume, FaEnvelope, FaFacebook, FaInstagram, FaTiktok } from "react-icons/fa";

const Uppernavbar = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 bg-lime-700 text-white p-4 text-sm md:text-xl">
      {/* Phone */}
      <div className="flex items-center space-x-2 ml-4 text-base md:text-lg">
        <FaPhoneVolume />
        <a
          href="https://wa.me/c/923118285630"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
        >
          +923118285630
        </a>
      </div>

      {/* Email */}
      <div className="flex items-center justify-center space-x-2 text-base md:text-lg">
        <FaEnvelope />
        <a
          href="mailto:wekandainterprises07@gmail.com"
          className="hover:underline"
        >
          wekandainterprises07@gmail.com
        </a>
      </div>

      {/* Social Media Icons */}
      <div className="flex items-center justify-end space-x-3 mr-4 text-xl md:text-2xl">
        <a
          href="https://www.facebook.com/share/15Y58ZuqT6/?mibextid=LQQJ4d"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:scale-105 transition-transform"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.instagram.com/wekanda_enterprise/profilecard/?igsh=MXNpcW1xZnV3bHFtMw=="
          target="_blank"
          rel="noopener noreferrer"
          className="hover:scale-105 transition-transform"
        >
          <FaInstagram />
        </a>
        <a
          href="https://www.tiktok.com/@wekandainterprieses?_t=8rpeR9XQolW&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:scale-105 transition-transform"
        >
          <FaTiktok />
        </a>
      </div>
    </div>
  );
};

export default Uppernavbar;
