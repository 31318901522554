import React from "react";
import { VscGraph } from "react-icons/vsc";
import { HiOutlineClipboardDocumentList } from "react-icons/hi2";
import { FaHandshake } from "react-icons/fa";

const Why = () => {
  return (
    <div className="py-16 ">
        <h1 className="text-4xl text-center font-bold mb-10">Why Choose Us</h1>
      <div className="container mx-auto px-6 md:px-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 items-stretch">
          {/* Grid Item 1 */}
          <div className="flex flex-col justify-between items-center p-8 bg-gray-800 text-gray-100 shadow-lg rounded-lg transform transition hover:scale-105 hover:shadow-2xl">
            <div className="flex flex-col items-center">
              <div className="text-lime-400 text-6xl mb-4">
                <VscGraph />
              </div>
              <h1 className="text-2xl font-bold text-gray-50 mb-4 text-center">
                Expertise and Innovation
              </h1>
              <p className="text-gray-300 text-justify">
                At Wekanda Enterprise, we take pride in our outstanding
                expertise and innovative techniques. Our team excels in
                services like Screen Printing, Embroidery, Heat Press (vinyl or
                sticker), Rhinestones, Denim Patches, and DTG (Direct-to-Garment).
                We bring your unique designs to life with precision, creativity,
                and unmatched quality.
              </p>
            </div>
          </div>

          {/* Grid Item 2 */}
          <div className="flex flex-col justify-between items-center p-8 bg-lime-700 text-gray-100 shadow-lg rounded-lg transform transition hover:scale-105 hover:shadow-2xl">
            <div className="flex flex-col items-center">
              <div className="text-gray-800 text-6xl mb-4">
                <HiOutlineClipboardDocumentList />
              </div>
              <h1 className="text-2xl font-bold text-gray-50 mb-4 text-center">
                Quality and Durability
              </h1>
              <p className="text-gray-300 text-justify">
                At Wekanda Enterprise, we use only the finest materials and the
                latest technology to guarantee that every piece we create is
                durable and long-lasting. Whether it's a custom-embroidered
                jacket or a rhinestone-studded t-shirt, our unwavering
                commitment to quality ensures your custom apparel not only
                looks fantastic but also stands the test of time.
              </p>
            </div>
          </div>

          {/* Grid Item 3 */}
          <div className="flex flex-col justify-between items-center p-8 bg-gray-800 text-gray-100 shadow-lg rounded-lg transform transition hover:scale-105 hover:shadow-2xl">
            <div className="flex flex-col items-center">
              <div className="text-lime-400 text-6xl mb-4">
                <FaHandshake />
              </div>
              <h1 className="text-2xl font-bold text-gray-50 mb-4 text-center">
                Customer Satisfaction
              </h1>
              <p className="text-gray-300 text-justify">
                At Wekanda Enterprise, your satisfaction is our top priority.
                From the initial consultation to the final product, we
                collaborate with you every step of the way to bring your vision
                to life. Our dedicated customer service team is always
                available to answer any questions and provide support, ensuring
                a smooth and enjoyable experience throughout the process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Why;
