import React from "react";
import { FaBox, FaTags, FaIdBadge } from "react-icons/fa";

const BulkDeals = () => {
  return (
    <div className="bg-white text-gray-800 min-h-screen p-10">
      <h1 className="text-4xl font-bold text-center mb-12 text-teal-600">Our Deals With Bulk</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {/* Free Custom Packing Bags */}
        <div className="bg-teal-100 text-teal-800 p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
          <FaBox className="text-5xl mb-4 text-teal-600" />
          <h2 className="text-2xl font-semibold mb-2">Free Custom Packing Bags</h2>
          <p className="text-lg">
            Enhance your brand image with high-quality, custom packing bags—completely free with bulk orders.
          </p>
        </div>

        {/* Free Custom Woven Neck Label */}
        <div className="bg-lime-100 text-lime-800 p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
          <FaTags className="text-5xl mb-4 text-lime-600" />
          <h2 className="text-2xl font-semibold mb-2">Free Custom Woven Neck Label</h2>
          <p className="text-lg">
            Add a personal touch with our custom woven neck labels, included free with bulk deals.
          </p>
        </div>

        {/* Free Custom Cards */}
        <div className="bg-yellow-100 text-yellow-800 p-6 rounded-lg shadow-lg flex flex-col items-center text-center">
          <FaIdBadge className="text-5xl mb-4 text-yellow-600" />
          <h2 className="text-2xl font-semibold mb-2">Free Custom Cards</h2>
          <p className="text-lg">
            Get professionally designed custom cards for free with bulk orders, perfect for branding.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BulkDeals;
