import React from "react";
import {FaHandshake, FaPalette, FaCut, FaRegCheckCircle } from "react-icons/fa";
import remitly from "../assets/remitily.png"
import WU from "../assets/wu.png"
import moneygram from "../assets/moneygram.png"
import transferwise from "../assets/wise.jpeg"

const PaymentMethods = () => {
  return (
    <div className="bg-white min-h-screen p-10">
      <h1 className="text-4xl font-bold text-center mb-12 text-lime-600">How We Take Payment</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-8">
        {/* Payment Methods */}
        <div className="bg-lime-700 text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">Payment Methods</h2>
          <ul className="space-y-2 text-lg">
            <li className="flex items-center  ">
              <img src={remitly} alt="" className="h-10 w-10"/> Remitly
            </li>
            <li className="flex items-center gap-2">
            <img src={WU} alt="" className="h-10 w-10"/> Western Union
            </li>
            <li className="flex items-center gap-2">
              <img src={moneygram} alt="" className="h-10 w-10"/> Money Gram
            </li>
            <li className="flex items-center gap-2">
            <img src={transferwise} alt="" className="h-10 w-10"/> Transfer Wise 
            </li>
          </ul>
        </div>

        {/* Payment Plan */}
        <div className="bg-gray-900 text-white p-6 rounded-lg shadow-lg">
          <h2 className="text-2xl font-semibold mb-4">Payment Plan For Bulk</h2>
          <ul className="space-y-4">
            <li className="flex items-start gap-4">
              <FaHandshake className="text-2xl " />
              <span>25% for confirmation of order</span>
            </li>
            <li className="flex items-start gap-4">
              <FaPalette className="text-2xl " />
              <span>25% when we check your fabric colors</span>
            </li>
            <li className="flex items-start gap-4">
              <FaCut className="text-2xl " />
              <span>25% when we check your cutting and printing</span>
            </li>
            <li className="flex items-start gap-4">
              <FaRegCheckCircle className="text-2xl " />
              <span>25% when we check the final look of your products</span>
            </li>
          </ul>
        </div>
      </div>

      {/* Trust Message */}
      <div className=" mt-7 bg-gray-900 text-white p-6 rounded-lg shadow-lg border-2 border-lime-600">
        <h2 className="text-2xl font-semibold text-lime-600 mb-4">Safe and Easy Deal</h2>
        <p>
          For our new customers, we provide a secure and convenient process to build trust and foster long-term collaboration. Let's work together for success!
        </p>
      </div>
    </div>
  );
};

export default PaymentMethods;
