import React from "react";
import { FaUser, FaClipboardCheck, FaRedo } from "react-icons/fa";

const HowWeWork = () => {
  return (
    <div className="bg-white text-black min-h-screen p-10">
      <h1 className="text-4xl font-bold text-center mb-12 text-lime-600">
        How We Work
      </h1>
      {/* Grid Layout */}
      <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
        {/* Step 1: Confirmation */}
        <div className="bg-lime-600 text-black p-6 rounded-lg shadow-lg flex flex-col items-center">
          <FaUser className="text-6xl mb-4" />
          <h2 className="text-2xl font-semibold mb-2 text-center">
            Step 1: Confirm Your Order
          </h2>
          <p className="text-lg text-center">
            Provide your full name, address, and an initial payment. We'll note
            this in our records to start your procedure.
          </p>
        </div>

        {/* Step 2: Initial Product */}
        <div className="bg-lime-500 text-black p-6 rounded-lg shadow-lg flex flex-col items-center">
          <FaClipboardCheck className="text-6xl mb-4" />
          <h2 className="text-2xl font-semibold mb-2 text-center">
            Step 2: Complete One Product
          </h2>
          <p className="text-lg text-center">
            We'll create one complete product and send it to you for approval.
            This ensures quality and satisfaction.
          </p>
        </div>

        {/* Step 3: Approval or Changes */}
        <div className="bg-black text-lime-400 p-6 rounded-lg shadow-lg border-2 border-lime-500 flex flex-col items-center">
          <FaRedo className="text-6xl text-lime-500 mb-4" />
          <h2 className="text-2xl font-semibold mb-2 text-center text-lime-500">
            Step 3: Approval or Modifications
          </h2>
          <p className="text-lg text-center">
            If approved, we proceed with the remaining products. If you need
            changes, we'll make them to ensure satisfaction.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowWeWork;
