import React from 'react';
import { useNavigate } from 'react-router-dom';

const Products = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate('/contact'); // Navigate to the contact page
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mx-10 my-16 gap-6">

     

      {/* Boxy Tees */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/Boxtes.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Boxy Tees</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>
 {/* Mesh Jersey */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/MeshJersey.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Mesh Jersey</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>
      {/* Double Sleeves */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/Doublesleeves.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Double Sleeves With Sun Fade</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Sweats With Distressed */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/sweats.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Sweats With Distressed</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Embroidery Hoodies */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/embroideryHoodies.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Cropped Embroidery Hoodies</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Sweatsuits */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/Sweatsuits.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Sweatsuits with Rhinestones</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Beanies */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/Beanies.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Beanies</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Nylon Pants */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/NylonPants.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Nylon Pants</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Zipper Hoodies */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/ZipperHoodies.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Triple Layer Zipper Hoodies</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Windbreaker Sets */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/WindbreakerSets.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Windbreaker Sets</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

      {/* Custom Packing Bags */}
      <div className="max-w-sm mx-auto bg-gray-900 text-white rounded-lg shadow-lg overflow-hidden">
        <video src="/videos/bags.mp4" controls preload="metadata" className="w-full"></video>
        <div className="p-6 text-center">
          <h2 className="text-xl font-bold mb-4">Custom Packing Bags with Your Brand Logo</h2>
          <button
            onClick={handleContactClick}
            className="bg-lime-800 text-white py-2 px-4 rounded hover:bg-lime-900"
          >
            Contact Now
          </button>
        </div>
      </div>

    </div>
  );
};

export default Products;
