import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/logo.png";
import { FaPhoneVolume, FaEnvelope, FaLocationArrow } from "react-icons/fa";

const FooterLink = [
  { id: 1, name: "Home", link: "/" },
  { id: 2, name: "About", link: "/aboutus" },
  { id: 3, name: "Services", link: "/services" },
  { id: 4, name: "Product", link: "/products" },
  { id: 5, name: "Contact", link: "/contactus" },
];

const FooterInfo = () => {
  const navigate = useNavigate();

  const handleNavigation = (link) => {
    navigate(link);
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <footer className="bg-gray-300 text-white py-10">
      <div className="container mx-auto px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-10">
          {/* Company Info */}
          <div className="space-y-5">
            <div className="flex items-center gap-3">
              <img src={Logo} alt="Logo" className="w-20 h-20" />
              <div>
                <h1 className="text-lime-900 text-2xl lg:text-3xl font-bold">
                  Wekanda Enterprises
                </h1>
                <p className="text-gray-900 text-sm font-light">
                  Excellence in Custom Apparel
                </p>
              </div>
            </div>
            <p className="text-gray-900 text-sm lg:text-base leading-relaxed">
              Wekanda Enterprise is a leading provider of premium custom apparel
              and accessories. Our services include Screen Printing, Embroidery,
              Heat Press, Rhinestones, Denim Patches, and DTG (Direct-to-Garment)
              printing.
            </p>
          </div>

          {/* Footer Links */}
          <div>
            <h2 className="text-lime-900 text-2xl lg:text-3xl font-semibold mb-4">
              Important Links
            </h2>
            <ul className="space-y-2">
              {FooterLink.map((link) => (
                <li key={link.id}>
                  <button
                    onClick={() => handleNavigation(link.link)}
                    className="text-gray-900 hover:text-lime-900 transition duration-300 text-sm lg:text-base"
                  >
                    {link.name}
                  </button>
                </li>
              ))}
            </ul>
          </div>

          {/* Services */}
          <div>
            <h2 className="text-lime-900 text-2xl lg:text-3xl font-semibold mb-4">
              Services
            </h2>
            <ul className="space-y-2">
              <li>
                <button
                  onClick={() => handleNavigation("/services")}
                  className="text-gray-900 hover:text-lime-900 transition duration-300 text-sm lg:text-base"
                >
                  Screen Printing
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/services")}
                  className="text-gray-900 hover:text-lime-900 transition duration-300 text-sm lg:text-base"
                >
                  Embroidery
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/services")}
                  className="text-gray-900 hover:text-lime-900 transition duration-300 text-sm lg:text-base"
                >
                  Heat Press
                </button>
              </li>
              <li>
                <button
                  onClick={() => handleNavigation("/services")}
                  className="text-gray-900 hover:text-lime-900 transition duration-300 text-sm lg:text-base"
                >
                  View More Services
                </button>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="space-y-5">
            <h2 className="text-lime-900 text-2xl lg:text-3xl font-semibold mb-4">
              Contact Info
            </h2>
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <FaPhoneVolume className="text-lime-900 text-xl" />
                <span className="text-gray-900 text-sm lg:text-base">
                  +923118285630
                </span>
              </div>
              <div className="flex items-center gap-3">
                <FaEnvelope className="text-lime-900 text-xl" />
                <span className="text-gray-900 text-sm lg:text-base">
                  wekandainterprises07@gmail.com
                </span>
              </div>
              <div className="flex items-center gap-3">
                <FaLocationArrow className="text-lime-900 text-xl" />
                <span className="text-gray-900 text-sm lg:text-base">
                  Sherwani Colony Haji Pura Sialkot
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="border-t border-gray-700 mt-10 pt-6 text-center text-gray-500 text-sm lg:text-base">
        &copy; 2024 Wekanda Enterprises. All rights reserved.
      </div>
    </footer>
  );
};

export default FooterInfo;
