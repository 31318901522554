import React from 'react'

import Abouthero from '../components/Abouthero'
import About from '../components/About'
import Why from '../components/Why'
import Testimonials from '../components/Testimonials'
import HowItWork from '../components/HowItWork'
import TestimonialsMsg from '../components/TestimonialsMsg'
const AboutUS = () => {
  return (
    <div>
      <Abouthero title="About US"/>
      <About/>
      <Why/>
      <Testimonials/>
      <HowItWork/>
      <TestimonialsMsg/>

    </div>
  )
}

export default AboutUS
