import React from "react";
import { useNavigate } from "react-router-dom";
import puff from "../assets/puff.jpg";
import embroidery from "../assets/Embroidery.jpg";
import heatpress from "../assets/heatpress.jpg";
import Rhinestones from "../assets/Rhinestones.jpg";
import DistressedDenimPatch from "../assets/Distressed Denim Patch.jpg";
import DTG from "../assets/DTG.jpg";

const ServicesCom = () => {
  const navigate = useNavigate(); 

  const handleContactClick = () => {
    window.scrollTo(0, 0); 
    navigate('/contact'); 
  }; 

  const services = [
    {
      title: "3D Puff Printing",
      image: puff,
      description:
        "Explore bold, textured designs with our cutting-edge 3D Puff Printing—perfect for creating standout apparel that turns heads.",
      button: "Contact Now",
    },
    {
      title: "Embroidery",
      image: embroidery,
      description:
        "Add a touch of elegance to your fabrics with our expert embroidery services, designed to bring your ideas to life with intricate craftsmanship.",
      button: "Contact Now",
    },
    {
      title: "Heat Press (Vinyl & Sticker)",
      image: heatpress,
      description:
        "Achieve sleek and durable designs with our precision Heat Press services—perfect for vinyl and sticker applications.",
      button: "Contact Now",
    },
    {
      title: "Rhinestones",
      image: Rhinestones,
      description:
        "Wekanda Enterprise offers Rhinestones, bringing sparkle and flair to your style. Explore our collection of dazzling, premium rhinestones designed to elevate your fashion and add a touch of brilliance to every piece.",
      button: "Contact Now",
    },
    {
      title: "Distressed Denim Patch",
      image: DistressedDenimPatch,
      description:
        "Wekanda Enterprise offers trendy Denim Patches, ideal for personalizing your style. Browse our collection to add a distinctive flair to your denim outfits and make them truly one-of-a-kind.",
      button: "Contact Now",
    },
    {
      title: "DTG",
      image: DTG,
      description:
        "Wekanda Enterprise proudly introduces DTG, providing innovative solutions crafted for the youth market. Explore our forward-thinking services designed to inspire and empower teenagers in today's dynamic world.",
      button: "Contact Now",
    },
  ];

  return (
    <div className="bg-gray-100 py-12">
      <div className="container mx-auto px-6 md:px-12">
        <h2 className="text-center text-3xl font-extrabold text-gray-800 mb-8">
          What We Offer
        </h2>
        <h2 className="text-center mt-5 mb-12">
          If you want to know more about Our services and products then visit our Social Media Pages
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 grid-rows-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg overflow-hidden transform transition hover:-translate-y-2 hover:shadow-2xl"
            >
              <img
                src={service.image}
                alt={service.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-800">
                  {service.title}
                </h3>
                <p className="text-gray-600 mt-3">{service.description}</p>
                
                <button
                 onClick={handleContactClick}
                  className="mt-4 px-4 py-2 bg-lime-500 text-white rounded-full shadow-lg hover:bg-lime-600"
                >
                  {service.button}
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesCom;
