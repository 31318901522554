import React from 'react'
import { Link } from 'react-router'
import bg from "../assets/bg.jpg"

const Hero = () => {
  return (
    <div className="relative bg-gray-100">
      {/* Background Image */}
      <div className="absolute inset-0">
        <img
          src={bg}
          alt="Hero Background"
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-black opacity-50"></div>
      </div>

      {/* Content */}
      <div className="relative z-10 flex flex-col items-center justify-center text-center text-white py-20 px-6 lg:px-20">
        <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold mb-6">
          Welcome to Wekanda Enterprise
        </h1>
        <p className="text-lg md:text-xl lg:text-2xl font-light mb-8 max-w-3xl">
          Elevate your apparel game with premium embroidery, vibrant prints, and
          unique designs crafted to perfection.
        </p>
        <Link
          to="/product" 
          className="bg-lime-600 text-white text-lg font-semibold py-3 px-8 rounded-lg shadow-lg hover:bg-lime-700 transition-transform transform hover:scale-105"
        >
          Explore Our Products
        </Link>
      </div>
    </div>
  )
}

export default Hero
