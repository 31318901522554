import React from 'react'
import Products from '../components/Products'
import Abouthero from '../components/Abouthero'

const Product = () => {
  return (
    <div>
       <Abouthero title="OUR PRODUCTS"/>
       <Products/>
    </div>
  )
}

export default Product
