import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Uppernavbar from './components/Uppernavbar';
import Navbar from './components/Navbar';
import FooterInfo from './components/FooterInfo';
import AboutUS from './pages/AboutUS';
import Services from './pages/Services';
import Product from './pages/Product';
import ContactUS from './pages/ContactUS';
import WhatsAppButton from './components/WhatsAppButton';

const App = () => {
  return (
    <BrowserRouter>
    
      <Uppernavbar />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/aboutus" element={<AboutUS />} />
        <Route path="/services" element={<Services />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<ContactUS />} />
        <Route path="*" element={<div>404 Page Not Found</div>} />
      </Routes>
      <WhatsAppButton/>
      <FooterInfo />
    </BrowserRouter>
  );
};

export default App;
