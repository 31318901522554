import React from 'react';
import { FaWhatsapp } from 'react-icons/fa';

const WhatsAppButton = () => {
  const openCatalog = () => {
    // https://wa.me/c/923118285630
    window.open('https://wa.me/923118285630?text=Hello!%20I%20would%20like%20to%20see%20your%20catalog.', '_blank');
  };

  return (
    <div
      className="fixed left-4 bottom-4 ml-5  bg-green-500 text-white rounded-full p-3 shadow-lg hover:bg-green-600 cursor-pointer"
      onClick={openCatalog}
    >
      <FaWhatsapp size={45} />
    </div>
  );
};

export default WhatsAppButton;
