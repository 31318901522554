import React, { useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import emailjs from "emailjs-com";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    service: "",
    message: "",
  });

  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // Track submission status

  // Handle phone number change
  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phoneNumber: value });
  };

  // Handle other input changes
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear previous messages
    setSuccessMessage("");
    setErrorMessage("");

    // Validate form fields
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phoneNumber || !formData.service || !formData.message) {
      setErrorMessage("Please fill in all fields before submitting.");
      return;
    }

    setIsSubmitting(true); // Start loader

    const serviceID = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateID = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    const templateParams = {
      from_name: `${formData.firstName} ${formData.lastName}`,
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      phone: formData.phoneNumber,
      service: formData.service,
      message: formData.message,
    };

    // Send email via EmailJS
    emailjs
      .send(serviceID, templateID, templateParams, publicKey)
      .then(
        (response) => {
          setSuccessMessage("Your message has been sent successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            service: "",
            message: "",
          });
        },
        (error) => {
          setErrorMessage("Something went wrong. Please try again later.");
          console.error("Failed to send email:", error);
        }
      )
      .finally(() => {
        setIsSubmitting(false); // Stop loader after submission
      });
  };

  return (
    <div className="bg-gray-900 text-white p-6 rounded-md w-[60%] mb-10 ml-[20%]">
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              placeholder="First Name"
              className="w-full p-2 text-black border border-gray-300 rounded"
            />
          </div>
          <div>
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              placeholder="Last Name"
              className="w-full p-2 text-black border border-gray-300 rounded"
            />
          </div>
        </div>
        <div className="mt-4">
          <label>Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            className="w-full p-2 text-black border border-gray-300 rounded"
          />
        </div>
        <div className="mt-4">
          <label>Phone Number</label>
          <PhoneInput
            country={"pk"} // Default country code
            value={formData.phoneNumber}
            onChange={handlePhoneChange}
            inputClass="w-full text-black"
            containerStyle={{ marginBottom: "1rem" }}
          />
        </div>
        <div className="mt-4">
          <label>Select Services</label>
          <select
            name="service"
            value={formData.service}
            onChange={handleChange}
            className="w-full text-black p-2 border border-gray-300 rounded"
          >
            <option value="">Select Services</option>
            <option value="Screen Printing">Screen Printing</option>
            <option value="Embroidery">Embroidery</option>
            <option value="Heat Press">Heat Press (vinyl or sticker)</option>
            <option value="Rhinestones">Rhinestones</option>
            <option value="Denim Patches">Denim Patches</option>
            <option value="DTG">DTG</option>
          </select>
        </div>
        <div className="mt-4">
          <label>Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Explain About Your Query"
            className="w-full text-black p-2 border border-gray-300 rounded"
          />
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-red-600 text-white p-3 rounded"
            disabled={isSubmitting} // Disable button while submitting
          >
            {isSubmitting ? (
              <div className="flex justify-center items-center">
                <div className="spinner-border animate-spin w-6 h-6 border-4 border-t-4 border-gray-200 rounded-full" />
              </div>
            ) : (
              "SUBMIT"
            )}
          </button>
        </div>

        {successMessage && (
          <p className="text-green-500 text-center mt-4">{successMessage}</p>
        )}

        {errorMessage && (
          <p className="text-red-500 text-center mt-4">{errorMessage}</p>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
