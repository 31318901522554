import React from "react";
import { FaPenNib, FaPaintBrush, FaShoppingCart } from "react-icons/fa";

const HowItWork = () => {
  return (
    <div className="py-16">
      <h1 className="text-4xl text-center font-bold mb-10">How It Works</h1>
      <p className="text-xl text-center mb-10">
        At Wekanda Enterprises, we provide a variety of custom apparel services to help bring your unique designs to life.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mx-14 items-stretch">
        {/* Step 1 */}
        <div className="flex flex-col items-center max-w-sm mx-auto p-6 bg-white border-2 border-lime-600 rounded-lg shadow-lg">
          {/* Icon Section */}
          <div className="flex items-center justify-center w-16 h-16 bg-lime-600 rounded-full mb-4">
            <FaPenNib className="text-white text-3xl" />
          </div>
          {/* Title */}
          <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
            Choose Your Service
          </h2>
          {/* Description */}
          <p className="text-gray-600 text-center">
            Screen Printing | Embroidery | Heat Press | Rhinestones | Denim Patches | DTG (Direct-to-Garment)
            Transform your apparel effortlessly with Wekanda Enterprises. From vibrant designs to premium customization, we make your vision a reality with unmatched quality and efficiency.
          </p>
        </div>

        {/* Step 2 */}
        <div className="flex flex-col items-center max-w-sm mx-auto p-6 bg-white border-2 border-lime-600 rounded-lg shadow-lg">
          {/* Icon Section */}
          <div className="flex items-center justify-center w-16 h-16 bg-lime-600 rounded-full mb-4">
            <FaPaintBrush className="text-white text-3xl" />
          </div>
          {/* Title */}
          <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
            Customize Your Design
          </h2>
          {/* Description */}
          <p className="text-gray-600 text-center">
            Upload your design or create a new one using our online design tool.
            Customize your apparel with text, graphics, and colors to make it
            uniquely yours. Our team is available to assist you in ensuring your
            design meets all specifications for the chosen service.
          </p>
        </div>

        {/* Step 3 */}
        <div className="flex flex-col items-center max-w-sm mx-auto p-6 bg-white border-2 border-lime-600 rounded-lg shadow-lg">
          {/* Icon Section */}
          <div className="flex items-center justify-center w-16 h-16 bg-lime-600 rounded-full mb-4">
            <FaShoppingCart className="text-white text-3xl" />
          </div>
          {/* Title */}
          <h2 className="text-xl font-bold text-gray-800 mb-4 text-center">
            Place Your Order
          </h2>
          {/* Description */}
          <p className="text-gray-600 text-center">
            Personalize your apparel effortlessly with our online design tool.
            Add text, graphics, and colors to craft a look that's uniquely yours.
            Need help? Our expert team is here to guide you every step of the way,
            ensuring your design meets all specifications for your selected service.
          </p>
        </div>
      </div>
    </div>
  );
};

export default HowItWork;
