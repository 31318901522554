import React from 'react'

import Hero from '../components/Hero'
import CallToAction from '../components/CallToAction'
import About from '../components/About'
import ServicesCom from '../components/ServicesCom'
import Why from '../components/Why'
import HowItWork from '../components/HowItWork'
import Testimonials from '../components/Testimonials'
import PaymentMethods from '../components/PaymentMethods'
import BulkDeals from '../components/BulkDeals'

const Home = () => {
  return (
    <div>
        <Hero/>
        <CallToAction/>
        <About/>
        <ServicesCom/>
        <PaymentMethods/> 
        <BulkDeals/>
        <Why/>
        <Testimonials/>
        <HowItWork/>
    </div>
  )
}

export default Home
