import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import { FaBars, FaTimes } from "react-icons/fa";
import logo from "../assets/logo.png";

const menubar = [
  { id: 1, name: "Home", link: "/" },
  { id: 2, name: "About Us", link: "/aboutus" },
  { id: 3, name: "Our Service", link: "/services" },
  { id: 4, name: "Our Products", link: "/product" },
  { id: 5, name: "Contact Us", link: "/contact" },
];

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation(); 

  // Scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [location]); 

  const handleQuoteClick = () => {
    setMenuOpen(false); // Close the menu if it's open on mobile
  };

  return (
    <nav className="shadow-md text-black bg-white sticky top-0 z-50">
      {/* Desktop & Tablet Navbar */}
      <div className="container mx-auto flex justify-between items-center py-4 px-8">
        {/* Logo */}
        <div>
          <Link to="/" className="flex gap-2 items-center">
            <img
              src={logo}
              alt="Logo"
              className="w-16 h-auto object-contain rounded-lg shadow-lg hover:shadow-xl transition duration-300"
            />
            <span className="font-bold text-sm text-lime-800">
              Wekanda <br /> Enterprises
            </span>
          </Link>
        </div>

        {/* Desktop Menu Links */}
        <div className="hidden md:flex items-center gap-6">
          <ul className="flex items-center gap-6 text-lg">
            {menubar.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.link}
                  className="hover:text-lime-700 transition duration-200"
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <Link to="/contact" className="bg-lime-500 text-white py-2 px-6 rounded-full hover:bg-lime-800 transition duration-200">
            Send a Quote
          </Link>
        </div>

        {/* Mobile Menu Toggle */}
        <div className="md:hidden">
          <button
            onClick={() => setMenuOpen(!menuOpen)}
            className="text-lime-700 text-3xl"
          >
            {menuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="bg-lime-900 text-white flex flex-col items-center py-6">
          <ul className="flex flex-col items-center gap-4 text-lg">
            {menubar.map((item) => (
              <li key={item.id}>
                <Link
                  to={item.link}
                  className="hover:text-lime-400 transition duration-200"
                  onClick={() => setMenuOpen(false)} // Close menu on click
                >
                  {item.name}
                </Link>
              </li>
            ))}
          </ul>
          <Link
            to="/contact"
            className="bg-lime-500 text-white py-2 px-6 rounded-full hover:bg-lime-800 transition duration-200 mt-4"
            onClick={handleQuoteClick} // Ensure scroll to top when clicked
          >
            Send a Quote
          </Link>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
