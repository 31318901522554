import React from 'react';
import person1 from "../assets/person1.jpg";
import person2 from "../assets/person2.jpg";
import person3 from "../assets/person3.jpg";

const TestimonialsMsg = () => {
  return (
    <div className="testimonials-section text-center py-10 bg-gray-100">
      <h2 className="text-3xl font-bold mb-8">What Clients Say!</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
        <div className="testimonial-card bg-white p-6 rounded-lg shadow-md">
          <p className="text-lg italic mb-4">
            "The embroidery work from Wekanda Enterprise exceeded our expectations. The attention to detail is impressive, and the finished product looks fantastic. Their service is top-notch—definitely a go-to for custom embroidery."
          </p>
          <div className="flex items-center justify-center">
            <img
              src={person1}
              alt="Mark X"
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <p className="font-semibold">Mark X</p>
            </div>
          </div>
        </div>

        <div className="testimonial-card bg-white p-6 rounded-lg shadow-md">
          <p className="text-lg italic mb-4">
            "Wekanda Enterprise transformed our designs with their screen printing service. The quality is outstanding, and the prints are vibrant and durable. Highly recommend for anyone needing professional and reliable printing solutions!"
          </p>
          <div className="flex items-center justify-center">
            <img
              src={person2}
              alt="John"
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <p className="font-semibold">John</p>
            </div>
          </div>
        </div>

        <div className="testimonial-card bg-white p-6 rounded-lg shadow-md">
          <p className="text-lg italic mb-4">
            "Wekanda Enterprise's rhinestones and denim patches added a unique touch to our apparel. The quality and creativity are unmatched. Their DTG printing also brought our designs to life beautifully. Excellent service all around!"
          </p>
          <div className="flex items-center justify-center">
            <img
              src={person3}
              alt="Roman"
              className="w-12 h-12 rounded-full mr-4"
            />
            <div>
              <p className="font-semibold">Roman</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsMsg;
