import React from "react";
import { useNavigate } from "react-router-dom";
import aboutus from "../assets/aboutus.png";

const About = () => {
  const navigate = useNavigate();

  const handleLearnMore = () => {
    navigate("/aboutus");
    window.scrollTo(0, 0); // Scroll to the top of the page
  };

  return (
    <div className="m-4 md:m-10 grid grid-cols-1 md:grid-cols-2 gap-8">
      {/* Image Section */}
      <div className="flex justify-center">
        <img
          src={aboutus}
          alt="About Us"
          className="rounded-3xl p-4 sm:p-6 md:p-10 w-full max-w-md md:max-w-lg"
        />
      </div>

      {/* Text Section */}
      <div className="mt-4 md:mt-16 space-y-4">
        <h1 className="text-lime-600 font-bold text-xl sm:text-2xl">
          Our Story
        </h1>
        <h2 className="text-2xl sm:text-3xl md:text-4xl font-bold">
          About Us
        </h2>
        <p className="text-sm sm:text-base md:text-lg">
          Wekanda Enterprise is your trusted partner for high-quality custom
          apparel and accessories, offering exceptional craftsmanship with
          unmatched efficiency. Specializing in a variety of services,
          including screen printing, embroidery, heat press (vinyl or adhesive),
          rhinestones, denim patches and DTG (direct to garment) printing, we
          turn your creative ideas into stunning, long-lasting designs.
        </p>
        <p className="text-sm sm:text-base md:text-lg">
          We pride ourselves on fast manufacturing and shipping, with production
          completed in just 2-3 days and shipping within 3-4 days, complete with
          tracking numbers for your convenience. Whether you're looking to
          customize unique t-shirts, hoodies or denim patches, our expert team
          ensures that each product perfectly combines style, durability and
          innovation.
        </p>
        <p className="text-sm sm:text-base md:text-lg">
          Choose Wekanda Enterprise for your customized needs and experience
          the perfect combination of accuracy, quality and speed. Your vision,
          our experience: together we create extraordinary projects.
        </p>
        <button
          onClick={handleLearnMore}
          className="bg-lime-700 text-white px-6 py-3 rounded hover:bg-lime-900"
        >
          Learn More
        </button>
      </div>
    </div>
  );
};

export default About;
