import React from 'react';
import abouthero from "../assets/Abouthero.jpg";

const Abouthero = ({ title }) => {
  return (
    <div
      className="relative h-[75vh] bg-cover bg-center flex justify-center items-center"
      style={{
        backgroundImage: `url(${abouthero})`,
      }}
    >
     
      <div className="absolute inset-0 bg-black opacity-50"></div>

     
      <div className="relative text-white text-center z-10 px-4">
        <h1 className="text-3xl sm:text-4xl mb-4">{title}</h1>
      </div>
    </div>
  );
};

export default Abouthero;
