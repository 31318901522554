import React from 'react'
import Abouthero from '../components/Abouthero'
import Offer from '../components/Offer'
import HowItWork from '../components/HowItWork'

const Services = () => {
  return (
    <div>
        <Abouthero title="OUR SERVICES"/>
        <Offer/>
        <HowItWork/>
    </div>
  )
}

export default Services
